import React, { FunctionComponent, ReactElement } from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import close from './close.svg';
import Hint from '../hint/white';
import styles from './modal.module.css';

export interface Props {
  visible: boolean;
  children: ReactElement | ReactElement[] | null | boolean | undefined;
  onCancel?: () => void;
  title?: string;
  className?: string;
  overlayClassName?: string;
  wide?: boolean;
  flexible?: boolean;
  hint?: string;
  shouldCloseOnOverlayClick?: boolean;
  contentWrapperClassName?: string;
  description?: string;
}

ReactModal.setAppElement('#adact-modal');

const Modal:FunctionComponent<Props> = ({ visible, flexible, wide, className, overlayClassName, hint, children, onCancel, title, shouldCloseOnOverlayClick = true, contentWrapperClassName, description }) => {

  return (
    <ReactModal
      isOpen={visible} shouldCloseOnEsc onRequestClose={onCancel}
      shouldReturnFocusAfterClose={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick} overlayClassName={cx(styles.overlay, overlayClassName)}
      className={cx(styles.content, className, {[styles.wide]: wide, [styles.flexible]: flexible})} closeTimeoutMS={200}
    >
      {onCancel
        ? <button className={cx(styles.cancelIcon, {[styles.withoutTitle]: !title})} onClick={onCancel}>
            <img src={close} alt="close" draggable={false}/>
          </button>
        : null
      }
      {title ? <p className={styles.title}>{title}</p> : null}
      {description && <p className={styles.description}>{description}</p> }
      {hint ? <Hint className={styles.hint}>{hint}</Hint> : null}
      <div className={cx(styles.contentWrapper, contentWrapperClassName)}>
        {children}
      </div>
    </ReactModal>
  );
}
export default Modal;
