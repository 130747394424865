import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { WhiteAutocomplete } from '../index';
import WhiteField from '../field/white';
import useOptions, { Options } from '../../../domain/useOptions';
import styles from './input.module.css';

//@ts-ignore
interface Props {
  className?: string;
  inputClassName?: string;
  label?: string;
  placeholder?: string;
  name: string;
  codeValue?: string;
  value: string | number;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onChangeCode?: (value: string) => void;
  activeValueDisplay?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  errors?: string[];
}

const WhitePhone:FunctionComponent<Props> = (props) => {
  const { phoneCodesWithFlagsOptions } = useOptions();
  const { className, label, errors, onChangeCode, name, required, disabled, placeholder, readOnly, inputClassName, onBlur, value, onChange, codeValue, activeValueDisplay } = props;
  const [ code, onChangeLocalCode ] = useState<string>(codeValue || '');

  useEffect(() => {
    onChangeLocalCode(codeValue || '');
  }, [codeValue]);

  useEffect(() => {
    const newCode = phoneCodesWithFlagsOptions.find((item: Options) => {
      return item.value === value;
    });//@ts-ignore
    if (newCode?.value && code !== newCode.value) onChangeLocalCode(newCode.value);
  }, [value]);

  const handleChangeCode = (codeValue: any) => {
    onChangeLocalCode(codeValue);
    if (onChangeCode) onChangeCode(codeValue);
  };

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <WhiteField name={name} className={className} label={label} errors={errors}>
      <div className={styles.inputs}>
        <WhiteAutocomplete withoutIcon activeValueDisplay={activeValueDisplay} disabled={disabled} value={code} className={styles.select} placeholder="Country" name={`${name}-code`} onSelect={handleChangeCode} options={phoneCodesWithFlagsOptions}/>
        <div className={styles.wrapper}>
          <input readOnly={readOnly} cypress-id={`${name}-component`} onBlur={onBlur} placeholder={placeholder} type="number" value={value} className={cx(styles.input, {[styles.hasError]: errors, [styles.readOnly]: readOnly}, inputClassName)} required={required} disabled={disabled} onChange={handleChange} name={name}/>
        </div>
      </div>
    </WhiteField>
  );
};

export default WhitePhone;
