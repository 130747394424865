import { createAction } from '../../utils/actions';

export const setCampaignList = createAction('campaigns/set_list');
export const addCampaignList = createAction('campaigns/add_list');
export const deleteCampaignFromList = createAction('campaigns/delete_campaign');
export const updateCampaignTitleInList = createAction('campaigns/update_campaign__title_in_list');
export const clearCampaigns = createAction('campaigns/clear');
export const clearOneAnalyticCampaign = createAction('campaigns/clear_one_analytic');
export const setOneAnalyticCampaign = createAction('campaigns/set_one_analytic');
export const setOneAnalyticCampaignPlayersActivity = createAction('campaigns/set_one_analytic_players_activity');
export const setOneAnalyticCampaignGameplays = createAction('campaigns/set_one_analytic_gameplays');
export const updateOneAnalyticCampaignGameplays = createAction('campaigns/update_one_analytic_gameplays');
export const setButtonsAndTraffics = createAction('campaigns/set_buttons_and_traffics');
export const removeAnalyticsCampaignGameplays = createAction('campaigns/remove_one_analytics_campaign_gameplay');
export const setNewsPosts = createAction('campaigns/set_news');
export const addNewsPosts = createAction('campaigns/add_news');
export const clearNewsPosts = createAction('campaigns/clear_news');
export const setCampaignsActivities = createAction('campaigns/set_campaignsActivities');
export const setOneTopPlayers = createAction('campaigns/set_top_players');
export const clearOneTopPlayers = createAction('campaigns/clear_top_players');
export const clearOneDailyStatistic = createAction('campaigns/clear_daily_statistics');
export const setOneDailyStatistic = createAction('campaigns/set_daily_statistics');
export const clearHoursStatistic = createAction('campaigns/clear_hours_statistics');
export const setHoursStatistic = createAction('campaigns/set_hours_statistics');
export const setField = createAction('campaigns/set_field');
export const setArrayField = createAction('campaigns/set_array_field');
export const setLeaderboardData = createAction('campaigns/set_leaderboard_data');
export const setResultsData = createAction('campaigns/set_results_data');
export const resetOneAnalyticCampaignStatistics = createAction('campaigns/reset_one_analytic');
export const setStatuses = createAction('campaigns/set_statuses');
