import addyConfirm from '../../../components/confirm';

export const useBrandLimitNotification = () => {
  return () => {
    addyConfirm({
      title: 'Oops',
      content: 'Unfortunately, your account has reached its brand limit.',
      onOk: () => window.open(process.env.REACT_APP_MEETING_LINK, '_blank'),
      okText: 'Talk to sales'
    });
  }
}
