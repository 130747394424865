import React, { Fragment, FunctionComponent, ReactElement, ReactText, useEffect, useMemo } from 'react';
import cx from 'classnames';
import Icon, { IconType, preloadIcons } from '../../icon';
import styles from './button.module.css';

export type ButtonType = 'yellow' | 'blue' | 'ghost' | 'link' | 'blue-ghost' | 'bluer-ghost' | 'yellow-ghost';
export type ButtonSize = 'lg' | 'md' | 'xs' | 'xxs';

type Child = undefined | boolean | ReactElement | ReactText;

type Props = {
  disabled?: boolean;
  type?: ButtonType;
  onClick?: (event: any) => void;
  size?: ButtonSize;
  className?: string;
  id?: string;
  name?: string;
  icon?: IconType;
  iconClassName?: string;
  backIcon?: IconType;
  loading?: boolean;
  htmlType?: 'button' | 'submit';
  children?: Child | Child[];
  dataTour?: string;
  onHover?: () => void;
  iconActive?: boolean;
  iconPosition?: 'left' | 'right';
}

const WhiteButton:FunctionComponent<Props> = (props: Props) => {
  const {
    disabled, type = 'blue', size = 'md',
    onClick, htmlType = 'button', icon,
    loading, children, className, name, backIcon,
    iconClassName, id, dataTour, onHover,
    iconActive = true,
    iconPosition = 'left',
  } = props;

  useEffect(() => {
    //@ts-ignore
    const icons:IconType[] = ['spinner', icon, backIcon].filter((icon: string | undefined) => Boolean(icon));
    preloadIcons(icons);
  }, [icon, backIcon]);

  const spinner = useMemo(() => {
    return <Icon className={styles.spinner} active={['yellow', 'blue'].includes(type)} icon="spinner" />;
  }, [type]);

  const iconElem = icon && <Icon icon={icon} active={!disabled && ['yellow', 'blue'].includes(type) && iconActive} className={cx(styles.icon, iconClassName)}/>;

  return (
    <button name={name} id={id} data-tour={dataTour} onMouseOver={onHover} cypress-id={`${name}-button`} className={cx(styles.button, { [styles.loading]: loading, [styles.onlyIcon]: !children && icon }, styles[type], styles[size], className)} type={htmlType} disabled={disabled} onClick={loading ? undefined : onClick}>
      {
        loading ? spinner
          :
          <Fragment>
            {iconPosition === 'left' ? iconElem : null}
            {children ? children : null}
            {backIcon ? <Icon icon={backIcon} active={!disabled && ['yellow', 'blue'].includes(type)} className={cx(styles.backIcon, iconClassName)}/> : null}
            {iconPosition === 'right' ? iconElem : null}
          </Fragment>
      }
    </button>
  );
};

export default WhiteButton;
