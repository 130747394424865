import I from 'seamless-immutable';
import {
  HubSpotConnection,
  Integration,
  IntegrationState,
  MailChimpConnection,
  Meta,
  WebhookEvent,
  WebhookEvents
} from './types';
import { userAdapter } from '../auth/adapters';

export function integrationAdapter(data: Integration) {
  return I({
    accountId: data.accountId,
    allowedUrls: data.allowedUrls,
    apiKey: data.apiKey,
    sendWebhooks: data.sendWebhooks,
    webhookUrl: data.webhookUrl,
    webhooks: data.webhooks,
    _id: data._id
  });
}

export function webhookEventAdapter(data: WebhookEvent) {
  return I({
    createdAt: data.createdAt,
    status: data.status,
    type: data.type,
    _id: data._id,
  });
}

export function metaAdapter(data: Meta) {
  return I({
    currentPage: data.currentPage,
    itemCount: data.itemCount,
    itemsPerPage: data.itemsPerPage,
    totalItems: data.totalItems,
    totalPages: data.totalPages
  });
}

export function webhookEventsAdapter(data: WebhookEvent[]) {
  return I(data.map(webhookEventAdapter));
}

export function webhookEventsObjectAdapter(data: WebhookEvents) {
  return I({
    meta: metaAdapter(data.meta),
    items: webhookEventsAdapter(data.items)
  });
}

export function hubSpotConnectionAdapter(data: HubSpotConnection) {
  return I({
    id: data?.id,
    title: data?.title,
    brandId: data?.brandId,
    createdBy: userAdapter(data?.createdBy),
  });
}

export function mailChimpConnectionAdapter(data: MailChimpConnection) {
  return I({
    id: data?.id,
    title: data?.title,
    brandId: data?.brandId,
    createdBy: userAdapter(data?.createdBy),
  });
}

export function integrationStoreAdapter():IntegrationState {
  return I({
    hubspotConnections: [],
    mailchimpConnections: [],
    integration: undefined,
    segments: [],
    events: {
      meta: {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 10,
        totalItems: 0,
        totalPages: 0
      },
      items: []
    }
  })
}
