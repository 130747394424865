import { ImmutableObject } from 'seamless-immutable';
import { PurchaseNotification } from '../../utils/type/purchaseNotifications/pruchaseNotification.type';

export enum PurchaseNotificationsStoreEnum {
  PURCHASE_NOTIFICATIONS = 'purchase_notifications',
}

export type PurchaseNotificationsState = ImmutableObject<{
  [PurchaseNotificationsStoreEnum.PURCHASE_NOTIFICATIONS]: PurchaseNotification[];
}>;
