import React, { Fragment, FunctionComponent, useCallback, useState } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { message } from 'antd';
import cx from 'classnames';
import Typo from '../../../components/typo';
import WhiteButton from '../../../components/form/whiteButton';
import styles from './upload.module.css';
import cloud from '../../../components/form/whiteUpload/cloud.svg';
import useUpload from '../../../domain/useUpload';
import Icon from '../../../components/icon';
import { cloneDeep } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import Spinner from '../../../components/spinner';

interface Props {
  accept?: string;
  onChange: (value: number[]) => void;
  setBusy: (value: boolean) => void;
}


type FileData = {
  dataUrl: string;
  name: string;
  loading: boolean;
  id?: number;
}

const useFileDrop = (accept: string, uploadFiles: (files: File[]) => void) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFileDrop(monitor);
    },
    canDrop(item, monitor) {
      return monitor.getItem().files?.length;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleFileDrop = useCallback(
    (monitor: DropTargetMonitor) => {
      if (monitor) {
        const files = [ ...monitor.getItem().files ];
        const filesToAdded: File[] = []
        files.forEach((file: File) => {
          const [tail] = file.name.split('.').reverse();
          if (accept.includes(tail.toLowerCase())) {
            filesToAdded.push(file)
          } else {
            message.error('File is unsupported');
          }
        })
        uploadFiles(filesToAdded);
      }
    }, [accept, uploadFiles]);

  return {
    canDrop,
    isOver,
    drop
  }
}

const FileUploader:FunctionComponent<Props> = ({ accept = '.png, .jpeg, .jpg', onChange, setBusy }) => {
  const { uploadFilesHubSpot } = useUpload();
  const [ files, setFiles ] = useState<FileData[]>([]);

  const uploadCallBack = useCallback((fileData: Record<string, any>[], currentFiles: FileData[]) => {
    const fileDataMapping = fileData.reduce((map, item) => ({ ...map, [item.name]: item.id }), {});
    const newFiles = cloneDeep(currentFiles);
    newFiles.forEach((item) => {
      if (fileDataMapping[item.name]) {
        item.loading = false;
        item.id = fileDataMapping[item.name];
      }
    });

    setBusy(false);
    setFiles(newFiles);
    onChange(newFiles.map(({ id }) => Number(id)).filter((item) => item) as number[]);
  }, [onChange, setBusy]);

  const uploadFiles = useCallback(async (newFiles: File[]) => {
    const promises: Promise<FileData>[] = [];

    const correctedFiles = newFiles.map((file: File) => {
      return new File([file], uuidV4(), {
        type: file.type,
        lastModified: file.lastModified,
      });
    });

    correctedFiles.forEach((file: File) => {
      const fileToLoad: Promise<FileData> = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({
          dataUrl: reader.result as string,
          name: file.name,
          loading: true,
        });
        reader.onerror = reject;
      });
      promises.push(fileToLoad);
    });

    const loadedFiles = await Promise.all(promises);

    setBusy(true);
    uploadFilesHubSpot(correctedFiles, (data) => uploadCallBack(data, allFiles));
    const allFiles = [ ...files, ...loadedFiles ];

    setFiles(allFiles);
  }, [files, setBusy, uploadCallBack, uploadFilesHubSpot]);

  const { canDrop, isOver, drop } = useFileDrop(accept, uploadFiles);

  const onChangeInput = useCallback(async (event: any) => {
    const files = [ ...event.target.files ];
    event.target.value = null;
    uploadFiles(files);
  }, [uploadFiles]);

  const onRemoveFile = useCallback((idx: number) => {
    const newFiles = cloneDeep(files);
    newFiles.splice(idx, 1);
    setFiles(newFiles);
    onChange(newFiles.map(({ id }) => id).filter((item) => item) as number[]);
  }, [files, onChange]);

  return (
    <Fragment>
      <div className={cx(styles.dndArea, { [styles.canDrop]: canDrop || isOver })} ref={drop}>
        { files.length ?
          <div className={styles.filesPreview} ref={drop}>
            <div className={styles.iconsWrapper}>
              {
                files.map((item, idx: number) => (
                  <div key={idx} className={styles.icon}>
                    <img src={item.dataUrl} alt={item.name}/>
                    { item.loading ?
                      <div className={styles.spinnerWrapper}>
                        <Spinner/>
                      </div>
                      :
                      <div className={styles.removeWrapper} onClick={() => onRemoveFile(idx)}>
                        <Icon icon="close" active/>
                      </div>
                    }
                  </div>
                ))
              }
            </div>
          </div>
          : null
        }
        <div className={styles.uploaderArea}>
          <div className={styles.iconWrapper}>
            <img className={styles.cloud} src={cloud} alt="cloud" draggable={false}/>
          </div>
          <Typo type="p" white>Drag files to upload or</Typo>
          <WhiteButton className={styles.inputFileBtn} size="xxs">
            <span>Browse files</span>
            <input className={styles.inputFile} type="file" multiple accept={accept} onChange={onChangeInput} cypress-id={`image`}/>
          </WhiteButton>
        </div>
      </div>
    </Fragment>
  );
};

export default FileUploader;
