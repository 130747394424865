import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import cx from 'classnames';
import WhiteField from '../field/white';
import styles from './input.module.css';
import InputType from '../../../utils/type/input.type';
import { debounce } from 'lodash';

type Props = InputType & {
  value: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlurCallBack?: () => void;
  label?: string;
  placeholder?: string;
  className?: string;
  errors?: string[];
  required?: boolean;
  readOnly?: boolean;
  maxLines?: number;
  maxLength?: number;
  disabled?: boolean;
  inputClassName?: string;
  nonResizeAble?: boolean;
}

const WhiteTextarea:FunctionComponent<Props> = (props) => {
  const {
    name, value, onChange,
    label, required, disabled,
    className, inputClassName,
    errors, readOnly, maxLines,
    maxLength,
    placeholder,
    onBlurCallBack,
    nonResizeAble = true, changeConfigField,
  } = props;

  const [ inputValue, setInputValue ] = useState<string>(value || '');
  const [ dirty, setDirty ] = useState<boolean>(false);

  const changeValue = (event: any) => {
    setDirty(true);
    if (onChange) onChange(event);
    setInputValue(event.target.value);
  }

  const debounceUpdate = debounce(() => {
    if (changeConfigField && dirty) {
      changeConfigField({ [name]: inputValue });
    }
  }, 500);

  useEffect(() => {
      debounceUpdate();
      return () => {
        debounceUpdate.cancel();
      }
    },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue]);


  return (
    <WhiteField className={className} errors={errors} name={name} label={label}>
      <textarea rows={maxLines} cypress-id={`${name}-component`} maxLength={maxLength} readOnly={readOnly} onBlur={onBlurCallBack} placeholder={placeholder} value={inputValue} className={cx(styles.input, {[styles.hasError]: errors, [styles.nonResizeAble]: nonResizeAble}, inputClassName)} required={required} disabled={disabled} onChange={changeValue} name={name}/>
    </WhiteField>
  );
}

export default WhiteTextarea;
