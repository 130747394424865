import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'antd';
import Modal from '../../modal';
import cx from 'classnames';
import Typo from '../../typo';
import WhiteButton from '../whiteButton';
import useUpload from '../../../domain/useUpload';
import styles from './upload.module.css';
import addyConfirm from '../../confirm';
import MediaFileList from './list';
import useFileSelect from './useFileSelect';
import useMediaFilesCategories from '../../../utils/hooks/useMediaFilesCategories';

interface Props {
  className?: string;
  name: string;
  value?: string;
  placeholder?: string;
  refProp?: any;
  campaignId?: number | null;
  disabled?: boolean;
  accept: string;
  onChange: (value: any, fileName: string) => void;
  btnType?: 'blue' | 'ghost' | 'yellow' | 'link';
  clearable?: boolean;
  white?: boolean;
  size?: 'xs' | 'lg' | 'md';
  type: 'font' | 'embed' | 'video' | 'leaderboard' | 'audio';
  mediaFile?: boolean;
  categories?: string[];
}

const WhiteFileUploader:FunctionComponent<Props> = ({ name, white, campaignId, clearable, type, size, btnType = 'blue', value, accept, disabled, refProp, onChange, placeholder, className, mediaFile, categories }) => {
  const { uploadFont, url, uploadEmbed, clearUrl, uploadVideo, cancelUpload, progress, upload } = useUpload();
  const [ showModal, onChangeShowModal ] = useState<boolean>(false);
  const [ file, setFile ] = useState<File | null>(null);
  const isUploading = useMemo(() => progress > 0, [progress]);

  const { onChangeInput, canDrop, drop, isOver } = useFileSelect({ type, setFile, accept });

  const onCloseModal = () => {
    if (isUploading) {
      addyConfirm({
        title: 'Upload in progress',
        content: 'Do you want cancel this operation?',
        onOk: () => {
          cancelUpload();
          onChangeShowModal(false);
          setFile(null);
        }
      });
      return;
    }
    onChangeShowModal(false);
    setFile(null);
  };

  useEffect(() => {
    if (url && !mediaFile) {
      onChange(url, file?.name || '');
      if (clearable) clearUrl();
      onChangeShowModal(false);
      setFile(null);
    }
  }, [url]);

  const onUpload = () => {
    if (file) {
      if (type === 'font') uploadFont(file);
      if (type === 'embed') uploadEmbed(file, 'common');
      if (type === 'leaderboard') uploadEmbed(file, 'leaderboard');
      if (type === 'video') uploadVideo(campaignId || undefined, file);
      if (type === 'audio' && file) upload(file, 'audio');
    }
  };

  const btnPlaceholder = useMemo(() => placeholder || 'Select file', [placeholder]);
  const [ valueName ] = useMemo(() => value ? value.split('/').reverse() : [], [value]);

  const onSelectFile = (fileUrl: string, fileName: string) => {
    onChange(fileUrl, fileName);
    onCloseModal();
  }

  const body = (
    file
      ?
      <div className={styles.previewWrapper}>
        <div className={cx(styles.dndArea, styles.filePlaceholder)}>
          <div className={styles.iconFileWrapper}>
            <FontAwesomeIcon icon={faFileAlt}/>
          </div>
          <div className={styles.fileName}>{file?.name}</div>
        </div>
        <div className={styles.footerBtns}>
          <WhiteButton type="ghost" onClick={onCloseModal} name={`${name}-component-cancel`}>Cancel</WhiteButton>
          <WhiteButton onClick={onUpload} disabled={!file || isUploading} name={`${name}-component-upload`}>Upload</WhiteButton>
        </div>
      </div>
      :
      <div className={cx(styles.dndArea, { [styles.canDrop]: canDrop || isOver })} ref={drop}>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon icon={faCloudUploadAlt}/>
        </div>
        <Typo white type="p">Drag file to upload</Typo>
        <Typo white type="p">or</Typo>
        <WhiteButton className={styles.inputFileBtn} name={`${name}-component-browsefiles`}>
          <span>Browse files</span>
          <input className={styles.inputFile} type="file" accept={accept} onChange={onChangeInput}/>
        </WhiteButton>
        { type === 'audio' && <Typo white type="p" className={styles.text}>Allowed file types: { accept }</Typo> }
      </div>
  );

  return (
    <Fragment>
      <WhiteButton className={cx(styles.uploaderButton, className)} size={size} disabled={disabled} name={`${name}-component-show`} type={btnType} onClick={() => onChangeShowModal(true)}>
        <div className={styles.valueText}>{valueName || btnPlaceholder}</div>
      </WhiteButton>
      <Modal
        visible={showModal}
        title={mediaFile ? 'Sound Library' : 'File Upload'}
        onCancel={onCloseModal}
        wide={mediaFile}
      >
        <div className={styles.modalWrapper}>
          {mediaFile && categories
            ? <MediaFileList type={type} selectFile={onSelectFile} categories={categories} accept={accept} file={file} onCancel={onCloseModal} />
            : (isUploading ? <Progress strokeColor="#3261EC" percent={progress} status="active"/> : body) }
        </div>
      </Modal>
    </Fragment>
  );
};

export default WhiteFileUploader;
