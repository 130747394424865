import React, { FunctionComponent, ReactElement, ReactText } from 'react';
import cx from 'classnames';
import Icon from '../icon';
import styles from './hint.module.css';

type Props = {
  children: string | ReactText | ReactText[] | ReactElement | any;
  className?: string;
  black?: boolean;
}

const Hint:FunctionComponent<Props> = ({ children, black, className }) => {
  return (
    <div className={cx(styles.whiteHint, { [styles.black]: black}, className)}>
      <Icon icon="info" className={styles.icon}/><p className={styles.label}>{children}</p>
    </div>
  )
};

export default Hint;
