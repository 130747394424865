import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './message.module.css';
import usePurchaseNotifications from '../../domain/payment/usePurchaseNotifications';
import Modal from '../../components/modal';
import Typo from '../../components/typo';
import { WhiteButton } from '../../components/form';
import { PurchaseNotification } from '../../utils/type/purchaseNotifications/pruchaseNotification.type';
import { addAWSUrl } from '../../utils/addAWSUrl';

const PurchaseNotifications:FunctionComponent = () => {
  const { deletePurchaseNotifications, purchaseNotifications } = usePurchaseNotifications();
  const [ show, setShow ] = useState<boolean>(false);
  const purchaseNotification: PurchaseNotification | undefined = purchaseNotifications[0];

  useEffect(() => {
    if (purchaseNotification) {
      setShow(true);
    }
  }, [purchaseNotification]);

  const readMore = () => {
    setShow(false);
    window.open('https://help.adact.me/how-to-buy-a-campaign', '_blank')
  }

  const close = () => {
    setShow(false);
    deletePurchaseNotifications(purchaseNotification._id);
  }

  return (
    <Modal visible={show} className={styles.modal}>
      <img src={addAWSUrl('spinningCoin.gif')} className={styles.coin} alt="coin"/>
      <div className={styles.wrapper}>
        <Typo type="p" className={styles.title}>{ purchaseNotification?.tokensAmount} Tokens have been added to your account!</Typo>
        <Typo type="p" className={styles.description}>You can now spend those tokens to unlock any campaign you have been working on.</Typo>
        <div className={styles.buttonsWrapper}>
          <WhiteButton type="ghost" className={styles.btn} onClick={close}>Close</WhiteButton>
          <WhiteButton className={styles.btn} onClick={readMore}>Read more</WhiteButton>
        </div>
      </div>
    </Modal>
  )
};

export default PurchaseNotifications;
