import { ImmutableObject } from 'seamless-immutable';
import { Course } from '../../utils/type/courses/course.type';

export enum CoursesStoreEnum {
  COURSES = 'courses',
}

export type CoursesState = ImmutableObject<{
  [CoursesStoreEnum.COURSES]: Course[];
}>;
