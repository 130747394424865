import useApi from '../useApi';
import { useDispatch, useSelector } from 'react-redux';
import { purchaseNotificationsSelector, setPurchaseNotificationsField } from '../../store/purchaseNotifications';
import { PurchaseNotificationsStoreEnum } from '../../store/purchaseNotifications/types';
import { PurchaseNotification } from '../../utils/type/purchaseNotifications/pruchaseNotification.type';

type UsePayments = {
  deletePurchaseNotifications: (id: string) => Promise<void>;
  purchaseNotifications: PurchaseNotification[];
  addNotification: (data: PurchaseNotification) => void;
  getPurchaseNotifications: () => void;
}

const usePurchaseNotifications = ():UsePayments => {
  const api = useApi();
  const dispatch = useDispatch();
  const purchaseNotifications: PurchaseNotification[] = useSelector(purchaseNotificationsSelector<PurchaseNotification[]>(PurchaseNotificationsStoreEnum.PURCHASE_NOTIFICATIONS));

  const getPurchaseNotifications = async () => {
    try {
      const response = await api.getPurchaseNotifications();
      dispatch(setPurchaseNotificationsField(response.data, PurchaseNotificationsStoreEnum.PURCHASE_NOTIFICATIONS));
    } catch (e) {
      console.log(e.message)
    }
  }

  const deletePurchaseNotifications = async (id: string) => {
    try {
      await api.deletePurchaseNotification(id);
      dispatch(setPurchaseNotificationsField(purchaseNotifications.filter((item) => item._id !== id), PurchaseNotificationsStoreEnum.PURCHASE_NOTIFICATIONS));
    } catch (e) {
      console.log(e.message)
    }
  }


  const addNotification = (notification: PurchaseNotification) => {
    const existingNotification = purchaseNotifications.find((item) => item._id === notification._id);

    let newNotifications: PurchaseNotification[];
    if (existingNotification) {
      newNotifications = purchaseNotifications.map((item) => item._id === notification._id ? notification : item);
    } else {
      newNotifications = [ ...purchaseNotifications, notification ];
    }

    dispatch(setPurchaseNotificationsField(newNotifications, PurchaseNotificationsStoreEnum.PURCHASE_NOTIFICATIONS));
  }

  return {
    purchaseNotifications,
    deletePurchaseNotifications,
    addNotification,
    getPurchaseNotifications,
  };
};

export default usePurchaseNotifications;
