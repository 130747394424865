import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import cx from 'classnames';
import styles from './toggler.module.css';
import InputType from '../../../utils/type/input.type';

type Props = InputType & {
  name: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
  className?: string;
  errors?: string[];
  width?: number;
  required?: boolean;
  disabled?: boolean;
  badGood?: boolean;
  checkedChildren?: string;
  unCheckedChildren?: string;
  inputClassName?: string;
  labelWidth?: number;
  inline?: boolean;
  reverseValue?: boolean;
}

const WhiteToggler:FunctionComponent<Props> = (props) => {
  const {
    name, checked, onChange,
    label, required, disabled,
    checkedChildren, unCheckedChildren,
    className, inputClassName, inline,
    width, labelWidth, badGood,
    changeConfigField, reverseValue, alignLabelRight
  } = props;

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event.target.checked);
    if (changeConfigField) changeConfigField({ [name]: reverseValue ? !event.target.checked : event.target.checked }, event);
  }, [onChange, changeConfigField, name, reverseValue]);

  return (
    <label style={{ width }} className={cx(styles.wrapper, className, {[styles.inline]: inline, [styles.sb]: Boolean(width)})}>
      {label ?
        <div className={styles.errorsLabelWrapper}>
          {label ? <label style={{ width: labelWidth}} className={cx(styles.label, {[styles.alignLabelRight]: alignLabelRight})} htmlFor={name}>{label}</label> : null}
        </div>
        : null}
      <div className={cx(styles.togglerWrapper, inputClassName)}>
        <input type="checkbox" cypress-id={`${name}-component`} required={required} onChange={handleChange} disabled={disabled} checked={checked} name={name} className={styles.checkbox}/>
        <span className={cx(styles.spotWrapper, {
          [styles.badGood]: badGood,
          [styles.bad]: checked,
          [styles.good]: !checked,
          [styles.checkedWithText]: checkedChildren && checked,
          [styles.unCheckedWithText]: unCheckedChildren && !checked
        })}>
          {checkedChildren && checked ? <span>{checkedChildren}</span> : null}
          <span className={styles.spot}/>
          {unCheckedChildren && !checked ? <span>{unCheckedChildren}</span> : null}
        </span>
      </div>
    </label>
  );
}

export default WhiteToggler;
