import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  disableSidebarSelector,
  fullPreviewSelector,
  sidebarSelector,
  sidebarTypeSelector, uiSelector
} from '../store/ui/selectors';
import { SidebarType, UIStoreEnum } from '../store/ui/types';
import {
  hideSidebarAction,
  maximizeSidebarAction,
  minimizeSidebarAction,
  setDisabledSidebar,
  setFullPreviewFalse,
  setFullPreviewTrue, setUIField,
  showSidebarAction,
} from '../store/ui/actions';
import { showTicketSelector } from '../store/common/selectors';
import { CommonStoreEnum } from '../store/common/types';
import { TicketShowType } from '../utils/type';
import { setCommonField } from '../store/common';

type iUseUI = {
  sidebar: boolean;
  sidebarType: SidebarType;
  showSidebar: (tiny?: boolean) => void;
  hideSidebar: (tiny?: boolean) => void;
  minimizeSidebar: () => void;
  maximizeSidebar: () => void;
  disableMaximize: () => void;
  allowMaximize: () => void;
  fullPreview: boolean;
  changeFullPreview: () => void;
  cancelFullPreview: () => void;
  showTicket: TicketShowType | null;
  setShowTicket: (show: TicketShowType | null) => void;
  tinySidebar: boolean;
  clearSidebar: boolean;
}

const useUI = ():iUseUI => {
  const dispatch = useDispatch();
  const sidebar = useSelector(sidebarSelector);
  const sidebarType = useSelector(sidebarTypeSelector);
  const fullPreview = useSelector(fullPreviewSelector);
  const disabledSidebar = useSelector(disableSidebarSelector);
  const showTicket = useSelector(showTicketSelector);
  const clearSidebar = useSelector(uiSelector<boolean>(UIStoreEnum.CLEAR_SIDEBAR));
  const tinySidebar = useSelector(uiSelector<boolean>(UIStoreEnum.TINY_SIDE_BAR));

  const setShowTicket = useCallback((value: TicketShowType | null) => {
    dispatch(setCommonField(value, CommonStoreEnum.SHOW_TICKET));
  }, [dispatch]);

  const showSidebar = useCallback((tiny?: boolean) => {
    dispatch(showSidebarAction());
    if (tiny) dispatch(setUIField(true, UIStoreEnum.TINY_SIDE_BAR));
  }, []);

  const hideSidebar = useCallback((tiny?: boolean) => {
    dispatch(hideSidebarAction());
    if (tiny) dispatch(setUIField(false, UIStoreEnum.TINY_SIDE_BAR));
  }, []);

  const minimizeSidebar = useCallback(() => {
    dispatch(minimizeSidebarAction());
  }, []);

  const maximizeSidebar = useCallback(() => {
    if (disabledSidebar) return;
    dispatch(maximizeSidebarAction());
  }, [disabledSidebar]);

  const disableMaximize = useCallback(() => {
    dispatch(setDisabledSidebar(true));
  }, []);

  const allowMaximize = useCallback(() => {
    dispatch(setDisabledSidebar(false));
  }, []);

  const changeFullPreview = useCallback(() => {
    const action = fullPreview ? setFullPreviewFalse : setFullPreviewTrue;
    dispatch(action());
  }, [fullPreview])

  const cancelFullPreview = useCallback(() => {
    dispatch(setFullPreviewFalse());
  }, [])

  return {
    sidebar,
    cancelFullPreview,
    changeFullPreview,
    fullPreview,
    sidebarType,
    disableMaximize,
    allowMaximize,
    minimizeSidebar,
    maximizeSidebar,
    showSidebar,
    hideSidebar,
    showTicket,
    setShowTicket,
    tinySidebar,
    clearSidebar,
  }
};

export default useUI;
