import React, { FunctionComponent, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Routes from './routes';
import RestrictionModal from './containers/restrictionModal';
import LeadsMessages from './containers/leadsMessage';
import { IOProvider } from './domain/useIO';
import './App.css';
import store from './store';
import { message } from 'antd';
import PurchaseNotifications from './containers/purchaseNotification';

message.config({
  maxCount: 3,
});


const App:FunctionComponent = () => {

  useEffect(() => {
    const tempMessage = document.getElementById('update-cache-temp-message')
    tempMessage?.remove();
  }, [])

  return (
    <div className="adact">
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <BrowserRouter>
            <IOProvider>
              <LeadsMessages />
              <PurchaseNotifications/>
              <RestrictionModal />
              <Routes/>
            </IOProvider>
          </BrowserRouter>
        </Provider>
      </DndProvider>
    </div>
  );
};

export default App;
